.game--link {
	font-size: 2em;
}

.store-badge {
	max-height:4em;
	max-width: 40vw;
	display: inline-block;
}

.ktktkt .download-links {
	display:flex;
	flex-direction: row;
}
.ktktkt .download-links > * {
	flex: 1 1;
}

.ktktkt .download-links > *:first-child {
	display: flex;
	justify-content: right;
}
.ktktkt .download-links > *:last-child {
	display: flex;
	justify-content: left;
}
.ktktkt .download-links > *:last-child {
	padding:10px;
}

.legal span {
	font-size: .5em;
	display: block;
}