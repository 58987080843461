@font-face {
    font-family: 'ktktkt';
    src: url('fonts/ktktkt/ktktkt.woff2') format('woff2'),
        url('fonts/ktktkt/ktktkt.woff') format('woff'),
        url('fonts/ktktkt/ktktkt.ttf') format('truetype'),
        url('fonts/ktktkt/ktktkt.svg#ktktkt') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body, html {
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.App {
  text-align: center;
  background: linear-gradient(blue, pink);
  height: stretch;
  width: stretch;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.App-logo {
}


.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
  padding: .2em 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "left logo right";
  grid-column: 0;
}


.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:stretch;
  overflow: auto;
  grid-column: 1;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: pink;
}

.header__section {
  display: grid;
  grid-template-rows: auto 1fr;
}

.header__section__games {
  display:grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  white-space: nowrap;
}

.header__section__title {
  pointer-events: none;
}
.header__section__opener {
  display: none;
  cursor: pointer;
}

@media only screen and (max-width: 1200px)  {
  .coverall {
    background: black;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    transition: opacity .5s;
  }
  .coverall.show {
    opacity: .5;
    pointer-events: inherit;
    z-index: 1;
  }
  .header__section__opener {
    display:inline-block;
  }
  .header__section__title {
    cursor: pointer;
    pointer-events: inherit;
  }
  .header__section__games {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    z-index: 2;
    background: blue;
    padding: 0 1em;
  }

  .header__section--mobile .header__section__games {
    position: absolute;
    left: 0;
    border-right: solid 1px black;
    top: 0;
    bottom: 0;
    transform: translateX(-100vw);
    transition: all .5s;
  }
  .header__section--mobile .header__section__games.open, .header__section--web .header__section__games.open {
    transform: translateX(0);
  }
  .header__section--web .header__section__games {
    position: absolute;
    right: 0;
    background-color: pink;
    border-left: solid 1px black;
    top: 0;
    bottom: 0;
    transform: translateX(100vw);
    transition: all .5s;
  }
}

.header__link--ktktkt {

  font-family: ktktkt;
  grid-column: 2;
}

.header__link--magicaipuzzle {

  grid-column: 2;
}

.header__link--magicaipuzzle img {
  height:1em;
  width:1em;
}


.header__link--hoverfrog {
  font-size: 1.5em;
  font-family: anton,sans-serif;
  grid-area: logo;
}
.header__link--hoverfrog .hoverfrog--link__hoverfrog {
  margin-bottom: -.5em;
  display:block;
} 
.header__link--hoverfrog .hoverfrog--link__digital {
  display: block;
  font-size: 1.6em;
  margin:0;
}

.header__link {
  display: inline-block;
}

footer a {
  text-decoration: none;
  color: black;
}

footer a:hover {
  color: blue;
}